import * as React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby';
import Page from '../components/Page'
import Container from '../components/Container'
import FooterBanner from '../components/DynamicBanner/FooterBanner'
import PageHeader from '../components/PageHeader'
import { Category } from '../components/Category'
import { breakpoints, colors, fonts } from '../styles/variables'

const ChannelsContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
  }
`

const InfoContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  height: 106px;
  background: ${colors.black};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 850px;
    height: 90px;
    margin-top: 10px;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100vw;
    maring-top: 5px;
  }
`

const Text = styled.div`
  font-family: ${fonts.codeProMono};
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: ${colors.white};
  margin-left: 120px;
  @media ${`(max-width: ${breakpoints.md}px)`} {
    font-family: ${fonts.ptMono};
    font-size: 14px;
    line-height: 16px;
    margin-left: 20px;
  }
`

const Button = styled.button`
  width: auto;
  height: 52px;
  margin-right: 150px;
  background: ${colors.red};
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding: 0px 20px;
  text-align: center;
  color:  ${colors.white};
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    height: 23px;
    margin-right: 20px;
    font-family: ${fonts.ptMono};
    font-size: 10px;
    line-height: 13px;
  }
`

const RadioChannelsPage = () => (
  <Page>
    <ChannelsContainer>
      <PageHeader title="Radiokanavat" />
      <InfoContainer>
        <Text>Radiokanavilla ohjelmaa ympäri vuorokauden</Text>
        <Button onClick={() => navigate('/ohjelmakartta')}>Ohjelmakartta</Button>
      </InfoContainer>
      <Category title="Pääkanava ja 11 paikalliskanavaa" type="list-radiochannels-live" description="" />
      <FooterBanner />
    </ChannelsContainer>
  </Page>
)

export default RadioChannelsPage
